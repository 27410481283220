// Dpendencies
import React from 'react'

const MaterialsListItem = ({ item }) => {
    return <div className="materials-list-item">
        <div>{item}</div>
    </div>
}

export default ({ materials }) => {
    return (
        <div className="row">
            { materials.map((item, index) => 
            <div  key={index} className="col-12 col-md-6 ">
                <MaterialsListItem className='materials-list-item' item={item}/>
            </div>
            ) }
        </div>
    )
}