// Dependencies
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MaterialList from "../components/materialsList"
import { FiPhoneCall, FiMail } from "react-icons/fi"
import { FaFacebook, FaWhatsapp } from "react-icons/fa"

const materials = [
  'Limpieza y destrucción de archivos',
  'Plásticos',
  'Papel / Cartón',
  'Palets / Maderas',
  'Hierros / Metales',
  'RSI y RCD'
]

const IndexPage = () => (
  <Layout>
    <SEO />
    <>
      <h1 className="title">
        Gestionamos sus residuos
      </h1>
      <MaterialList materials={materials}/>
      <h2 className="subtitle">
        Consúltenos como hacerlo de forma responsable y activa 
      </h2>
      <div className="social">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/vrecresiduos"
          alt="Facebook"
        >
          <FaFacebook />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=34608015816"
          alt="Whatsapp"
        >
          <FaWhatsapp />
        </a>
      </div>
      <div className="contact-details">
        <span>
          <FiPhoneCall className="icon" />
          <a href="tel:+34608015816">+34 608 015 816</a>
        </span>
        <span>
          <FiMail className="icon" />
          <a href="mailto:administracion@vrec.es">
            administracion@vrec.es
          </a>
        </span>
        <span>
          <FiMail className="icon" />
          <a href="mailto:comercial@vrec.es">
            comercial@vrec.es
          </a>
        </span>
      </div>
    </>
  </Layout>
)

export default IndexPage
